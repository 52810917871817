<template>
  <setup-table
    :title="`Destination`"
    :items="items"
    :headers="headers"
    :loading="loading"
    :clearItem="!showForm || (editedItem && !editedItem.id)"
    :hideCreate="prospective"
    :deleteLabel="prospective ? 'Remove' : 'Delete'"
    @createItem="createItem()"
    @editItem="editItem"
    @deleteItems="deleteItems"
    showSearch
    pagination
  >
    <template #itemForm v-if="showForm">
      <destination-form
        :value="editedItem"
        @closeForm="closeForm"
        @submit="saveItem"
        ref="destinationForm"
      ></destination-form>
    </template>
  </setup-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  GET_DESTINATIONS,
  GET_PROSPECTIVE_DESTINATIONS,
  SAVE_DESTINATION,
  DELETE_DESTINATIONS,
  REMOVE_PROSPECT_FROM_DESTINATIONS,
} from '@/store/modules/Destination/actions';

import { GET_STOPS } from '@/store/modules/Stop/actions';

import SetupTable from './SetupTable.vue';
import DestinationForm from './DestinationForm.vue';

export default {
  name: 'DestinationSetupTable',
  inject: ['eventHub'],
  components: { SetupTable, DestinationForm },
  data() {
    return {
      showForm: false,
      items: [],
      vehicleDestinations: [],
      editedItem: {},
      loading: true,
      prospective: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.onresize = () => (this.windowWidth = window.innerWidth);

    this.eventHub.$on('toggleProspectiveDestinations', () => {
      this.toggleProspectiveDestinations();
    });

    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('toggleProspectiveDestinations');
  },
  computed: {
    ...mapGetters('destination', ['destinations', 'prospectiveDestinations']),
    headers() {
      if (this.windowWidth > 1650)
        return [
          { text: 'Name', value: 'name' },
          { text: 'Category', value: 'category' },
          { text: 'Address', value: 'address.address' },
          { text: 'City', value: 'address.city' },
          { text: 'State', value: 'address.state' },
          { text: 'Zip', value: 'address.zip' },
        ];
      else
        return [
          { text: 'Name', value: 'name' },
          { text: 'Category', value: 'category' },
          { text: 'Address', value: 'fullAddress' },
        ];
    },
  },
  methods: {
    ...mapActions('destination', [
      DELETE_DESTINATIONS,
      GET_DESTINATIONS,
      GET_PROSPECTIVE_DESTINATIONS,
      REMOVE_PROSPECT_FROM_DESTINATIONS,
      SAVE_DESTINATION,
    ]),
    ...mapActions('stop', [GET_STOPS]),
    async fetchItems() {
      await this[GET_DESTINATIONS]({ table: 1 });
      await this[GET_PROSPECTIVE_DESTINATIONS]({ table: 1 });

      this.items = this.prospective
        ? this.prospectiveDestinations.filter((e) => !e.prospectRemoved)
        : this.destinations;

      this.items.forEach((location) => {
        location.fullAddress = this.getFullAddress(location);
      });

      this.loading = false;

      await this.getStops();
    },
    createItem() {
      this.$refs.destinationForm?.resetForm();

      this.editedItem = { id: 0, name: '', address: {} };
      this.showForm = true;
    },
    editItem(item) {
      this.$refs.destinationForm?.resetForm();

      this.editedItem = { ...item, address: item.address ? item.address : {} };
      this.showForm = true;
    },
    async saveItem(item) {
      try {
        if (item.category.toLowerCase() == 'school (my district)') {
          this.$myalert.error('Schools must be added in the Locations Table');
          return;
        }

        if (this.prospective) item.prospective = 0;

        const r = await this[SAVE_DESTINATION](item);

        if (r && r.id) {
          this.$myalert.success('Destination saved');

          await this.fetchItems();

          this.editedItem = this.items.find((e) => e.id == r.id);
          this.closeForm();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItems(items) {
      const yes = await this.$myconfirm(
        `Are you sure you want to ${this.prospective ? 'remove' : 'delete'} ${
          items.length > 1
            ? `these ${this.prospective ? 'Prospective' : ''} Destinations`
            : `this ${this.prospective ? 'Prospective' : ''} Destination`
        }? This operation cannot be undone.`
      );

      if (yes) {
        const r = this.prospective
          ? await this[REMOVE_PROSPECT_FROM_DESTINATIONS](items.map((e) => e.id))
          : await this[DELETE_DESTINATIONS](items.map((e) => e.id));

        if (!r.done) return;

        this.$myalert.success(`${items.length > 1 ? 'Destinations' : 'Destination'} deleted`);
        this.showForm = false;
        this.fetchItems();
      }
    },
    closeForm() {
      this.showForm = false;
    },
    toggleProspectiveDestinations() {
      this.prospective = !this.prospective;

      this.items = this.prospective
        ? this.prospectiveDestinations.filter((e) => !e.prospectRemoved)
        : this.destinations;
    },
    getFullAddress(item) {
      const itemAddress = item.address;

      let fullAddress = '';

      if (!item.address) return fullAddress;

      if (itemAddress.address) fullAddress = itemAddress.address;
      if (itemAddress?.city) fullAddress += ', ' + itemAddress.city;
      if (itemAddress?.state) fullAddress += ', ' + itemAddress.state;
      if (itemAddress?.zip) fullAddress += ', ' + itemAddress.zip;

      return fullAddress;
    },
  },
};
</script>

<style lang="scss">
.table-action {
  margin-left: 10px;
}
</style>
